import { entryPoint } from 'react_rails/entryPoint';
import Page from '@/components/Page';
import styles from './styles/error.module.scss';
import TextLink, { TextLinkSize } from '@/components/TextLink';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export default entryPoint(function error({ title, subtitle }: { title: string; subtitle?: string }) {
  const [, { makeHomePath }] = useApplicationContainer();

  return (
    <>
      <Header />
      <div className={styles.Container}>
        <div className={styles.Text}>
          <div className={styles.Title}>{title}</div>
          {subtitle && <div className={styles.Subtitle}>{subtitle}</div>}
          <TextLink size={TextLinkSize.LARGE} href={makeHomePath()}>
            Homepage
          </TextLink>
          <TextLink size={TextLinkSize.LARGE} href="https://scorebooklivesupport.zendesk.com">
            Support
          </TextLink>
        </div>
      </div>
      <Footer />
    </>
  );
});
